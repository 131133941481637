import { Col, Row, Form, Button } from 'react-bootstrap'
import ReactSelect from 'components/base/ReactSelect'

import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'

import { useParams } from 'react-router-dom'

import lng from 'lang'

import ItemTemplate from './components/row_template'

const purchase_order = () => {
  const po = useParams().id

  const save = () => {}

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
        <div>
          <h2 className="mb-2">
            {po
              ? lng.get('purchase', 'edit_purchase_order')
              : lng.get('purchase', 'add_new_purchase_order')}
          </h2>
          <h5 className="text-body-tertiary fw-semibold">
            {lng.get('purchase', 'purchase_order_sub_title')}
          </h5>
        </div>
        <div className="d-flex flex-wrap gap-2">
          <Button variant="phoenix-secondary" type="button">
            {lng.get('purchase', 'discard')}
          </Button>
          <Button variant="phoenix-primary" type="button">
            {lng.get('purchase', ['save', 'draft'])}
          </Button>
          <Button variant="primary" type="button" onClick={save}>
            {lng.get('purchase', ['publish', 'product'])}
          </Button>
        </div>
      </div>
      <Row
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingBottom: '25px',
          borderRadius: '10px',
          backgroundColor: '#fff'
        }}
        className="light__bg-light dark__text-gray-100 dark__bg-gray-1000"
        bg="light"
      >
        <Row className="g-1">
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['purchase', 'order', 'description'])}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', [
                  'purchase',
                  'order',
                  'description'
                ])}
              />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['purchase', 'order', 'number'])}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', [
                  'purchase',
                  'order',
                  'number'
                ])}
              />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Label>{lng.get('purchase', 'currency')}</Form.Label>
            <ReactSelect options={[]} />
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Label>{lng.get('purchase', 'tags')}</Form.Label>
            <ReactSelect options={[]} />
          </Col>
        </Row>
        <Row className="g-1">
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['Vendors'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['purchase', 'request'])}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', ['purchase', 'request'])}
              />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['order', 'date'])}</Form.Label>
              <Form.Control
                type="date"
                placeholder={lng.get('purchase', ['order', 'date'])}
              />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['Person in charge'])}
              </Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-1">
          <Col
            xs={12}
            lg={6}
            md={6}
            sm={6}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['Department'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['Customers'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['Sale', 'invoices'])}
              </Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-1">
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['Project'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['Type'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['number_of_days_owned'])}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', ['order', 'date'])}
              />
            </Form.Group>
          </Col>
          <Col
            xs={12}
            lg={3}
            md={3}
            sm={3}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%' }}
          >
            <Form.Group>
              <Form.Label>
                {lng.get('purchase', ['delivery', 'date'])}
              </Form.Label>
              <Form.Control
                type="date"
                placeholder={lng.get('purchase', ['delivery', 'date'])}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-1">
          <Col
            xs={12}
            lg={6}
            md={6}
            sm={6}
            className="g-2"
            style={{ paddingRight: '1%', paddingLeft: '1%', paddingTop: '1%' }}
          >
            <Form.Group>
              <Form.Label>{lng.get('purchase', ['product'])}</Form.Label>
              <ReactSelect options={[]} />
            </Form.Group>
          </Col>
        </Row>
        <ItemTemplate />
        <Row>
          <Col xs={8} lg={8} md={8} sm={8} className="g-2"></Col>
          <Col xs={4} lg={4} md={4} sm={4} className="g-2">
            <Row>
              <Col xs={8} lg={8} md={8} sm={8} className="g-2">
                {lng.get('purchase', ['sub_total'])}
              </Col>
              <Col xs={4} lg={4} md={4} sm={4} className="g-2">
                LKR. 4,500.00
              </Col>
            </Row>
            <Row>
              <Col xs={8} lg={8} md={8} sm={8} className="g-2">
                {lng.get('purchase', ['discount'])}
              </Col>
              <Col xs={4} lg={4} md={4} sm={4} className="g-2">
                <Row>
                  <Col xs={6} lg={6} md={6} sm={6} className="g-2">
                    <Form.Control type="text" placeholder="%" />
                  </Col>
                  <Col xs={6} lg={6} md={6} sm={6} className="g-2">
                    <Form.Control type="text" placeholder="Discount" />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={8} lg={8} md={8} sm={8} className="g-2">
                {lng.get('purchase', ['shipping_info'])}
              </Col>
              <Col xs={4} lg={4} md={4} sm={4} className="g-2">
                <Form.Control type="text" placeholder="Shipping Info" />
              </Col>
            </Row>
            <Row>
              <Col xs={8} lg={8} md={8} sm={8} className="g-2">
                {lng.get('purchase', ['grand_total'])}
              </Col>
              <Col xs={4} lg={4} md={4} sm={4} className="g-2">
                LKR. 4,000.00
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default purchase_order
