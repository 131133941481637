import { Nav } from 'react-bootstrap'

const AccountingTab = () => {
  return (
    <>
      <Nav variant="tabs" defaultActiveKey="link-1" className="fs-9">
        <Nav.Item>
          <Nav.Link eventKey="link-1">Inventory asset account</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-2">Income Account</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-3">Expense Account</Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  )
}

export default AccountingTab
