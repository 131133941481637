import Button from 'components/base/Button'
import Dropzone from 'components/base/Dropzone'
import TinymceEditor from 'components/base/TinymceEditor'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'
import { Card, Col, Form, Row } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import InventoryTab from './item_components/InventoryTab'
import ItemRightTop from './item_components/itemRightTop'
import { Variant, SelectType, Item } from 'modules/inventory/types'

import apiRequest from 'apiRequest'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactSelect from 'react-select'

import { toast } from 'react-toastify'

import lng from 'lang'

const AddProduct = () => {
  const [itemDetail, setItemDetail] = useState<Item>()
  const [productType, setProductType] = useState<SelectType | null>(null)
  const [productWarehouses, setProductWarehouses] = useState<SelectType[]>([])
  const [productTaxes, setProductTaxes] = useState<SelectType[]>([])
  const [productUnit, setProductUnit] = useState<SelectType | null>(null)
  const [productGroup, setProductGroup] = useState<SelectType | null>(null)
  const [productVendor] = useState<SelectType[] | null>(null)
  const [productTag, setProductTag] = useState<SelectType[] | null>(null)
  const [productSubGroup, setProductSubGroup] = useState<SelectType | null>(
    null
  )
  const [productCode, setProductCode] = useState<string>('')
  const [productName, setProductName] = useState<string>('')
  const [productDescription, setProductDescription] = useState<string>('')
  const [productBarcode, setProductBarcode] = useState<string>('')
  const [productSKUName, setProductSKUName] = useState<string>('')
  const [productSKUCode, setProductSKUCode] = useState<string>('')
  const [productDefaultPrice, setProductDefaultPrice] = useState<number | null>(
    itemDetail?.default_price || null
  )
  const [productDefaultProfitRate, setProductDefaultProfitRate] = useState<
    number | null
  >(itemDetail?.default_profit_rate || null)
  const [productSellingPrice, setProductSellingPrice] = useState<number | null>(
    itemDetail?.default_profit_rate || null
  )
  const [variants, setVariants] = useState<Variant[]>([])

  const [units, setUnits] = useState<SelectType[]>([])
  const [unitsSelectOpen, setUnitsSelectOpen] = useState<boolean>(false)
  const [unitSelected, setUnitSelected] = useState<SelectType>()

  const itemId = useParams().id
  const [loading, setLoading] = useState<boolean>(itemId ? true : false)

  const navigate = useNavigate()

  useEffect(() => {
    const ITEM_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/get',
      true
    )

    ITEM_REQUEST.send(
      {
        item: itemId
      },
      (data: { status: string; message: string; data: Item }) => {
        if (data.status == 'success') {
          setItemDetail(data.data)
          setUnitSelected(data.data.unit)
          setProductTag(data.data.tags || null)
          setProductDefaultPrice(data.data.default_price)
          setLoading(false)
        } else {
          navigate('module/inventory/items')
        }
      }
    )

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/units/search',
      true
    )

    API_REQUEST.send(
      {
        type: 'select'
      },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setUnits(data.data)
        }
      }
    )
  }, [itemId])

  const save = () => {
    const productTagIds: string[] = []

    productTag &&
      productTag.forEach(tag => {
        productTagIds.push(tag.value)
      })

    const tmpToast = toast('Saving product', {
      position: 'top-right',
      type: 'info',
      isLoading: true,
      theme: 'light',
      className: 'text-sm'
    })

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/item/add',
      true
    )
    API_REQUEST.send(
      {
        type: productType,
        unit: productUnit,
        group: productGroup,
        vendor: productVendor,
        tags: productTag,
        sub_group: productSubGroup,
        code: productCode,
        name: productName,
        description: productDescription,
        barcode: productBarcode,
        sku: productSKUName,
        sku_code: productSKUCode,
        variants: variants
      },
      (data: { status: string; message: string; data: SelectType[] }) => {
        console.log({
          type: productType,
          unit: productUnit,
          group: productGroup,
          vendor: productVendor,
          tags: productTag,
          sub_group: productSubGroup,
          code: productCode,
          name: productName,
          description: productDescription,
          barcode: productBarcode,
          sku: productSKUName,
          sku_code: productSKUCode,
          variants: variants
        })
        if (data.status == 'success') {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'success',
            autoClose: 2000
          })

          navigate('/module/inventory/items', { replace: true })
        } else {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'error',
            autoClose: 2000
          })
        }
      }
    )
  }

  // eslint-disable-next-line
  const handleProductTypeChange = (selected: any) => {
    setProductUnit(selected.value != undefined ? selected.value : '')
    setUnitsSelectOpen(false)
    setUnitSelected(selected)
  }

  const searchUnits = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/units/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setUnits(data.data)
        }
      }
    )
  }

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <form className="mb-9">
        <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
          <div>
            <h2 className="mb-2">
              {itemId
                ? lng.get('inventory', 'edit_item')
                : lng.get('inventory', 'add_new_item')}
            </h2>
            <h5 className="text-body-tertiary fw-semibold">
              {lng.get('inventory', 'item_sub_title')}
            </h5>
          </div>
          <div className="d-flex flex-wrap gap-2">
            <Button variant="phoenix-secondary" type="button">
              {lng.get('inventory', 'discard')}
            </Button>
            <Button variant="phoenix-primary" type="button">
              {lng.get('inventory', ['save', 'draft'])}
            </Button>
            <Button variant="primary" type="button" onClick={save}>
              {lng.get('inventory', ['publish', 'product'])}
            </Button>
          </div>
        </div>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <Card className="p-1 m-0">
              <Card.Body>
                {loading ? (
                  <>
                    <Row className="align-items-center justify-content-between g-2">
                      <Col xs={12} lg={12}>
                        <SkeletonTheme highlightColor="#444">
                          <Skeleton
                            count={5}
                            style={{ opacity: 0.2, marginTop: '20px' }}
                            duration={2}
                          />
                        </SkeletonTheme>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-between g-2 mt-2">
                      <Col xs={12} lg={12}>
                        <SkeletonTheme highlightColor="#444">
                          <Skeleton
                            count={5}
                            style={{ opacity: 0.2, marginTop: '20px' }}
                            duration={2}
                          />
                        </SkeletonTheme>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="">
                      <Col xs={12} xl={12} md={12} lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            {lng.get('inventory', ['product', 'title'])}
                          </Form.Label>
                          <Form.Control
                            placeholder="Write title here..."
                            className="mb-1"
                            onChange={e => setProductName(e.target.value)}
                            defaultValue={itemDetail?.name}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col xs={12} xl={4} md={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            Product Code
                          </Form.Label>
                          <Form.Control
                            placeholder="Write product code here..."
                            className="mb-1"
                            onChange={e => setProductCode(e.target.value)}
                            defaultValue={itemDetail?.code}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} xl={8} md={8} lg={8}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            Product Unit
                          </Form.Label>
                          <ReactSelect
                            options={units}
                            placeholder="Select item type..."
                            className="small"
                            classNamePrefix="react-select"
                            onInputChange={searchUnits}
                            onChange={handleProductTypeChange}
                            onMenuOpen={() => setUnitsSelectOpen(true)}
                            onBlur={() => setUnitsSelectOpen(false)}
                            menuIsOpen={unitsSelectOpen}
                            value={unitSelected}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="">
                      <Col xs={12} xl={4} md={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            Barcode
                          </Form.Label>
                          <Form.Control
                            placeholder="Write barcode here..."
                            className="mb-1"
                            onChange={e => setProductBarcode(e.target.value)}
                            defaultValue={itemDetail?.barcode}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} xl={4} md={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            SKU Code
                          </Form.Label>
                          <Form.Control
                            placeholder="Write SKU code here..."
                            className="mb-1"
                            onChange={e => setProductSKUCode(e.target.value)}
                            defaultValue={itemDetail?.sku_code}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} xl={4} md={4} lg={4}>
                        <Form.Group className="mb-3">
                          <Form.Label className="fs-8 text-start p-0 text-capitalize">
                            SKU Name
                          </Form.Label>
                          <Form.Control
                            placeholder="Write SKU name here..."
                            className="mb-1"
                            onChange={e => setProductSKUName(e.target.value)}
                            defaultValue={itemDetail?.sku}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="mb-1">
                      <Form.Group className="mb-3">
                        <Form.Label className="fs-8 text-start p-0 text-capitalize">
                          Product Description
                        </Form.Label>
                        <TinymceEditor
                          options={{
                            height: '10rem',
                            placeholder: 'Write a description here...'
                          }}
                          onChange={e => setProductDescription(e)}
                          value={itemDetail?.description}
                        />
                      </Form.Group>
                    </div>
                    <div className="mb-1">
                      <Form.Group className="mb-3">
                        <Form.Label className="fs-8 text-start p-0 text-capitalize">
                          Display images
                        </Form.Label>
                        <Dropzone
                          size="sm"
                          className="mb-1"
                          accept={{
                            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                          }}
                        />
                      </Form.Group>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} xl={4}>
            <Row className="g-2">
              <Col xs={12} xl={12}>
                <ItemRightTop
                  className="mb-3"
                  loading={loading}
                  productGroup={productGroup}
                  productTag={productTag}
                  productSubGroup={productSubGroup}
                  productType={productType}
                  item={itemDetail}
                  setProductGroup={setProductGroup}
                  setProductTag={setProductTag}
                  setProductSubGroup={setProductSubGroup}
                  setProductType={setProductType}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Card className="mt-3 p-1">
          <Card.Body>
            <Row className="g-3">
              <h4 className="mb-3">Inventory</h4>
              <InventoryTab
                item={itemDetail}
                variants={variants}
                productDefaultPrice={productDefaultPrice}
                productDefaultProfitRate={productDefaultProfitRate}
                productSellingPrice={productSellingPrice}
                setVariants={setVariants}
                productTaxes={productTaxes}
                setProductTaxes={setProductTaxes}
                productWarehouses={productWarehouses}
                setProductWarehouses={setProductWarehouses}
                setProductDefaultProfitRate={setProductDefaultProfitRate}
                setProductDefaultPrice={setProductDefaultPrice}
                setProductSellingPrice={setProductSellingPrice}
              />
            </Row>
          </Card.Body>
        </Card>
      </form>
    </div>
  )
}

export default AddProduct
