//NO SHADOW | BORDER BOTTOM | BOLD
export const bb_fg = {
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  fontWeight: 'bold',
  borderBottom: '1px solid #dee2e6'
}

//NO SHADOW | BORDER BOTTOM | NO BOLD
export const bb_fg_no_bold = {
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  borderBottom: '1px solid #dee2e6',
  fontSize: '14px'
}
