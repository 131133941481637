import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSave,
  faUndoAlt,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import Button from 'components/base/Button'
import PageBreadcrumb from 'components/common/PageBreadcrumb'
import { defaultBreadcrumbItems } from 'data/commonData'
import { Col, Row, Accordion } from 'react-bootstrap'

// import { faKey, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useEffect, useReducer, useState } from 'react'

import { Form, FormControl, InputGroup, Table, Tab, Nav } from 'react-bootstrap'
import ReactSelect from 'components/base/ReactSelect'

import apiRequest from 'apiRequest'

import { toast } from 'react-toastify'

import {
  Customer,
  SelectType,
  PaymentMode,
  Tag,
  ItemSection
} from '../../Types'

import ItemsTab from './invoice_tabs/items_tab'
import OptionalItemsTab from './invoice_tabs/optional_items_tab'

const getCurrentDate = () => {
  const today = new Date()
  const year = today.getFullYear()
  const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const day = String(today.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

const Invoice = () => {
  const [key, setKey] = useState<string | null>('home')
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [sections, setSections] = useState<ItemSection[]>([])
  const [optionalItems, setoptionalItems] = useState<ItemSection[]>([])

  const [customer, setCustomer] = useState<Customer | null>(null)
  const [invoiceType, setInvoiceType] = useState<SelectType | null>(null)
  const [invoicePaymentModes, setInvoicePaymentModes] = useState<PaymentMode[]>(
    []
  )
  const [invoiceTags, setInvoiceTags] = useState<Tag[]>([])
  const [invoiceDiscountType, setInvoiceDiscountType] = useState('1')
  const [invoiceDiscountPercent, setInvoiceDiscountPercent] = useState(0)
  const [invoiceDiscountTotal, setInvoiceDiscountTotal] = useState(0)
  const [invoiceNote, setInvoiceNote] = useState('')
  const [invoiceCurrency, setInvoiceCurrency] = useState('lkr')
  const [invoiceDate, setInvoiceDate] = useState(getCurrentDate())
  const [invoiceDueDate, setInvoiceDueDate] = useState('')
  const [invoiceTerms, setInvoiceTerms] = useState('')
  const [invoiceClientNote, setInvoiceClientNote] = useState('')

  const [subTotal, setSubTotal] = useState(0)
  const [adjustment, setAdjustment] = useState(0)
  const [total, setTotal] = useState(0)

  const [customerSelectOpen, setCustomerSelectOpen] = useState(false)
  const [invoiceTypeSelectOpen, setInvoiceTypeSelectOpen] = useState(false)
  const [paymentModesSelectOpen, setPaymentModesSelectOpen] = useState(false)
  const [tagsSelectOpen, setTagsSelectOpen] = useState(false)

  const [searchedCustomers, setSearchedCustomers] = useState<SelectType[]>([])
  const [searchedInvoiceTypes, setInvoiceTypes] = useState<SelectType[]>([])

  const [invoiceStartingNumber, setInvoiceStartingNumber] = useState('')
  const [invoiceNumberPrefix, setInvoiceNumberPrefix] = useState('')

  const [paymentModes, setPaymentModes] = useState<PaymentMode[]>([])
  const [defaultPaymentModes, setDefaultPaymentModes] = useState<PaymentMode[]>(
    []
  )
  const [paymentModesLoaded, setPaymentModesLoaded] = useState(false)

  const [tags, setTags] = useState<Tag[]>([])
  const [defaultTags, setDefaultTags] = useState<Tag[]>([])
  const [tagsLoaded, setTagsLoaded] = useState(false)

  const navigate = useNavigate()

  // const { id } = useParams()

  useEffect(() => {
    const PM_API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/payment/modes/get',
      true
    )
    PM_API_REQUEST.send(
      { type: 'select' },
      (data: { status: string; message: string; data: PaymentMode[] }) => {
        if (data.status == 'success') {
          const defaultPaymentModes: PaymentMode[] = []
          data.data.forEach(ele => {
            if (ele.default) {
              defaultPaymentModes.push(ele)
            }
          })
          setPaymentModes(data.data)
          setDefaultPaymentModes(defaultPaymentModes)
          setInvoicePaymentModes(defaultPaymentModes)

          setPaymentModesLoaded(true)
        }
      }
    )

    const TAG_API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/tags/get',
      true
    )
    TAG_API_REQUEST.send(
      { type: 'select', default: true },
      (data: { status: string; message: string; data: Tag[] }) => {
        if (data.status == 'success') {
          setDefaultTags(data.data)
          setInvoiceTags(data.data)
          setTagsLoaded(true)
        }
      }
    )
  }, [])

  const searchCustomers = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/clients/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setSearchedCustomers(data.data)
        }
      }
    )
  }

  const searchInvoiceTypes = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/sales/invoice/types/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setInvoiceTypes(data.data)
        }
      }
    )
  }

  const searchTags = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/tags/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: Tag[] }) => {
        if (data.status == 'success') {
          setTags(data.data)
        }
      }
    )
  }

  // eslint-disable-next-line
  const handleCustomerChange = (selected: any) => {
    setCustomer(selected.value != undefined ? selected.value : '')
    setCustomerSelectOpen(false)
  }

  // eslint-disable-next-line
  const handlePaymentModeChange = (selected: any) => {
    setInvoicePaymentModes(selected)
    setPaymentModesSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleInvoiceTypeChange = (selected: any) => {
    setInvoiceType(selected.value != undefined ? selected.value : '')
    setInvoiceTypeSelectOpen(false)

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/sales/invoice/types/get',
      true
    )
    API_REQUEST.send(
      { invoiceType: selected.value },
      (data: {
        status: string
        message: string
        data: { prefix: string; starting_number: string }
      }) => {
        if (data.status == 'success') {
          setInvoiceNumberPrefix(data.data.prefix)
          setInvoiceStartingNumber(data.data.starting_number)
        }
      }
    )
  }

  // eslint-disable-next-line
  const handleTagChange = (selected: any) => {
    setInvoiceTags(selected)
    setTagsSelectOpen(false)
  }

  const saveInvoice = () => {
    const paymentMethods: string[] = []
    invoicePaymentModes.forEach(element => {
      paymentMethods.push(element.value)
    })

    const tmpToast = toast('Saving invoice', {
      position: 'top-right',
      type: 'info',
      isLoading: true,
      theme: 'light',
      className: 'text-sm'
    })

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/sales/invoice/add',
      true
    )
    API_REQUEST.send(
      {
        client: customer,
        items: sections,
        payment_methods: paymentMethods,
        discount: invoiceDiscountTotal,
        adjustment: adjustment,
        discount_percentage: invoiceDiscountPercent,
        currency: invoiceCurrency,
        invoice_date: invoiceDate,
        due_date: invoiceDueDate,
        note: invoiceNote,
        discount_type: invoiceDiscountType,
        invoice_type: invoiceType,
        terms: invoiceTerms,
        client_note: invoiceClientNote,
        tags: invoiceTags
      },
      (data: { status: string; message: string; invoice: string }) => {
        if (data.status == 'success') {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'success',
            autoClose: 2000
          })
          navigate('/module/sales/invoices/' + data.invoice, {
            replace: true
          })
        }
      }
    )
  }

  const calculateInvoiceTotal = () => {
    let total = 0
    sections.forEach(element => {
      element.items.forEach(element => {
        total = total + element.total
      })
    })

    setSubTotal(total)
    setTotal(total - invoiceDiscountTotal + adjustment)
  }

  const keyDown = (event: { keyCode: number }) => {
    if (event.keyCode === 27) {
      sections.forEach(element => {
        element.items = element.items.slice(0, -1)
      })

      // setSections(new_sections)
      forceUpdate()
      calculateInvoiceTotal()
    }
  }

  return (
    <div onKeyUp={keyDown}>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <Row className="align-items-center justify-content-between g-3 mb-4">
        <Col xs="auto">
          <h2 className="mb-0">Create New Invoice</h2>
        </Col>
        <Col xs="auto" className="d-flex flex-wrap gap-2 gap-sm-3">
          <Button
            variant="phoenix-danger"
            startIcon={<FontAwesomeIcon className="me-2" icon={faTrashAlt} />}
          >
            Delete invoice
          </Button>
          <Button
            variant="phoenix-secondary"
            startIcon={<FontAwesomeIcon className="me-2" icon={faUndoAlt} />}
          >
            Reset invoice
          </Button>
        </Col>
      </Row>
      <Row
        style={{
          paddingLeft: '25px',
          paddingRight: '25px',
          paddingBottom: '25px',
          borderRadius: '10px',
          backgroundColor: '#fff'
        }}
        className="light__bg-light dark__text-gray-100 dark__bg-gray-1000"
        bg="light"
      >
        <Row className="g-3 mb-4">
          <Col xs={6} sm={12} md={12} lg={6}>
            <Row className="mt-0">
              <Col xs={12}>
                <Form.Label style={{ paddingLeft: 0 }}>
                  Select Customer
                </Form.Label>
                <ReactSelect
                  options={searchedCustomers}
                  placeholder="Select customer..."
                  className="small"
                  classNamePrefix="react-select"
                  onInputChange={searchCustomers}
                  onChange={handleCustomerChange}
                  onMenuOpen={() => setCustomerSelectOpen(true)}
                  menuIsOpen={customerSelectOpen}
                />
              </Col>
              <Col xs={12}>
                <Form.Label style={{ paddingLeft: 0 }}>
                  Select Invoice Type
                </Form.Label>
                <ReactSelect
                  options={searchedInvoiceTypes}
                  placeholder="Select invoice type..."
                  className="small"
                  classNamePrefix="react-select"
                  onInputChange={searchInvoiceTypes}
                  onChange={handleInvoiceTypeChange}
                  onMenuOpen={() => setInvoiceTypeSelectOpen(true)}
                  menuIsOpen={invoiceTypeSelectOpen}
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={6}>
                <h5>Bill To</h5>
                <p style={{ fontSize: '14px' }}>Global Cloud Media (pvt) Ltd</p>
                <p style={{ fontSize: '14px' }}>
                  No 20,
                  <br /> Old Kasbawa Road,
                  <br /> Nugegoda
                </p>
              </Col>
              <Col xs={6}>
                <h5>Ship to</h5>
                <p style={{ fontSize: '14px' }}>Global Cloud Media (pvt) Ltd</p>
                <p style={{ fontSize: '14px' }}>
                  No 20,
                  <br /> Old Kasbawa Road,
                  <br /> Nugegoda
                </p>
              </Col>
            </Row>
            {invoiceType != null ? (
              <Row className="mt-1">
                <Col xs={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      {invoiceNumberPrefix}
                    </InputGroup.Text>
                    <FormControl
                      placeholder="Invoice Number"
                      aria-label="Invoice Number"
                      value={invoiceStartingNumber}
                      readOnly={true}
                    />
                  </InputGroup>
                </Col>
              </Row>
            ) : null}
            <Row className="mt-1">
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Invoice Date
                  </InputGroup.Text>
                  <FormControl
                    type="date"
                    id="invoice_date"
                    placeholder="Invoice Date"
                    aria-label="Invoice Date"
                    value={invoiceDate}
                    onChange={e => setInvoiceDate(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Due Date</InputGroup.Text>
                  <FormControl
                    type="date"
                    placeholder="Due Date"
                    id="due_date"
                    onChange={e => setInvoiceDueDate(e.target.value)}
                    aria-label="Due Date"
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <Row className="mt-0" style={{ top: 0, marginBottom: 10 }}>
              {tagsLoaded && (
                <Col xs={6}>
                  <Form.Label style={{ paddingLeft: 0 }}>Tags</Form.Label>
                  <ReactSelect
                    options={tags}
                    isMulti={true}
                    onInputChange={searchTags}
                    onChange={handleTagChange}
                    onMenuOpen={() => setTagsSelectOpen(true)}
                    menuIsOpen={tagsSelectOpen}
                    placeholder="Select Tags..."
                    defaultValue={defaultTags}
                  />
                </Col>
              )}
              {paymentModesLoaded && (
                <Col xs={6}>
                  <Form.Label style={{ paddingLeft: 0 }}>
                    Allowed payment modes for this invoice
                  </Form.Label>
                  <ReactSelect
                    options={paymentModes}
                    placeholder="Select payment modes..."
                    isMulti={true}
                    defaultValue={defaultPaymentModes}
                    onChange={handlePaymentModeChange}
                    onMenuOpen={() => setPaymentModesSelectOpen(true)}
                    menuIsOpen={paymentModesSelectOpen}
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-3" style={{ marginBottom: 10 }}>
              <Col xs={12}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Discount Type
                  </InputGroup.Text>

                  <Form.Select
                    id="discount_type"
                    onChange={e => setInvoiceDiscountType(e.target.value)}
                  >
                    <option value="1" selected>
                      Before Tax
                    </option>
                    <option value="0">After Tax</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">Currency</InputGroup.Text>

                  <Form.Select
                    id="default_language"
                    onChange={e => setInvoiceCurrency(e.target.value)}
                  >
                    <option value="lkr">LKR</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Col xs={6}>
                <InputGroup className="mb-3">
                  <InputGroup.Text id="basic-addon1">
                    Created By
                  </InputGroup.Text>
                  <Form.Select
                    id="default_language"
                    //   onChange={handleSelectChange}
                  >
                    <option value="male">Global Cloud Media</option>
                  </Form.Select>
                </InputGroup>
              </Col>
              <Row className="mt-1">
                <Col xs={12}>
                  <Form.Label style={{ paddingLeft: 0 }}>Admin Note</Form.Label>
                  <FormControl
                    as={'textarea'}
                    onChange={e => setInvoiceNote(e.target.value)}
                    id="note"
                    placeholder="Admin Note"
                    aria-label="Admin Note"
                    rows={5}
                    className="w-100"
                    style={{ width: '100%' }}
                  />
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <Row className="mt-1 mb-5">
          <Tab.Container
            id="controlled-tab-example"
            activeKey={key}
            onSelect={k => setKey(k)}
          >
            <Nav variant="tabs" className="mb-1" style={{ margin: 0 }}>
              <Nav.Item
                style={{
                  border: '1px solid #dee2e6',
                  padding: '1px',
                  width: '10%',
                  textDecoration: 'none',
                  textAlign: 'center',
                  margin: 0
                }}
              >
                <Nav.Link eventKey="home">Product Lines</Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{
                  border: '1px solid #dee2e6',
                  padding: '1px',
                  width: '10%',
                  textDecoration: 'none',
                  textAlign: 'center'
                }}
              >
                <Nav.Link eventKey="profile">Optional Products</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="home">
                <ItemsTab
                  items={sections}
                  setItems={setSections}
                  calculateInvoiceTotal={calculateInvoiceTotal}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="profile">
                <OptionalItemsTab
                  items={optionalItems}
                  setoptionalItems={setoptionalItems}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Row>
        <Row className="mt-1">
          <Col xs={8}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="border-top">
                <Accordion.Header>Terms and Conditions</Accordion.Header>
                <Accordion.Body className="pt-0">
                  <FormControl
                    as={'textarea'}
                    placeholder="Terms and Conditions"
                    aria-label="Terms and Conditions"
                    onChange={e => setInvoiceTerms(e.target.value)}
                    rows={5}
                    className="w-100"
                    style={{ width: '100%' }}
                  />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Client Note</Accordion.Header>
                <Accordion.Body className="pt-0">
                  <FormControl
                    as={'textarea'}
                    placeholder="Client Note"
                    aria-label="Client Note"
                    onChange={e => setInvoiceClientNote(e.target.value)}
                    rows={5}
                    className="w-100"
                    style={{ width: '100%' }}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col xs={4}>
            <Table hover size="sm">
              <tbody>
                <tr>
                  <th style={{ width: '50%' }}>Sub Total</th>
                  <td style={{ textAlign: 'right' }}>LKR. {subTotal}</td>
                </tr>
                <tr>
                  <th>Discount</th>
                  <td style={{ textAlign: 'right' }}>
                    <Row style={{ gap: '0px' }}>
                      <Col xs={1} style={{ padding: '0px' }}></Col>
                      <Col xs={4} style={{ padding: '0px' }}>
                        <FormControl
                          type="number"
                          step={0.1}
                          placeholder="%"
                          min={0}
                          onBlur={calculateInvoiceTotal}
                          defaultValue={invoiceDiscountPercent}
                          onChange={e => {
                            setInvoiceDiscountPercent(
                              parseFloat(e.target.value)
                                ? parseFloat(e.target.value)
                                : 0
                            )
                            setInvoiceDiscountTotal(
                              parseFloat(e.target.value)
                                ? (subTotal * parseFloat(e.target.value)) / 100
                                : 0
                            )
                            forceUpdate()
                          }}
                        />
                      </Col>
                      <Col xs={6} style={{ padding: '0px' }}>
                        <FormControl
                          type="number"
                          placeholder="0.00"
                          className="w-100"
                          style={{ width: '100%' }}
                          value={invoiceDiscountTotal}
                          min={0}
                          onBlur={calculateInvoiceTotal}
                          onChange={e => {
                            setInvoiceDiscountTotal(
                              parseFloat(e.target.value)
                                ? parseFloat(e.target.value)
                                : 0
                            )
                            forceUpdate()
                          }}
                        />
                      </Col>
                    </Row>
                  </td>
                </tr>
                <tr>
                  <th>Adjustment</th>
                  <td style={{ textAlign: 'right' }}>
                    <FormControl
                      type="number"
                      placeholder="0.00"
                      className="w-100"
                      style={{ width: '100%' }}
                      onBlur={calculateInvoiceTotal}
                      defaultValue={adjustment}
                      onChange={e => {
                        setAdjustment(
                          parseFloat(e.target.value)
                            ? parseFloat(e.target.value)
                            : 0
                        )
                        forceUpdate()
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td style={{ textAlign: 'right' }}>LKR. {total}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button variant="info" className="btn btn-sm btn-default">
              Save As Draft
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="success"
              className="btn btn-sm btn-successs"
              onClick={saveInvoice}
            >
              Save <FontAwesomeIcon icon={faSave} />
            </Button>
          </Col>
        </Row>
      </Row>
    </div>
  )
}

export default Invoice
