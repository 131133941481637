import { Form, Col, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import ReactSelect from 'components/base/ReactSelect'

import { SelectType, ClientContact, DB_ClientContact } from '../../types'
import { toast } from 'react-toastify'

import apiRequest from 'apiRequest'

const ClientContactModel = ({
  contact,
  client,
  refreshComponent,
  setRefreshComponent,
  setContactModelOpen
}: {
  contact?: DB_ClientContact
  client: string
  refreshComponent: boolean
  setRefreshComponent: (e: boolean) => void
  setContactModelOpen: (e: boolean) => void
}) => {
  const [title, setTitle] = useState(contact?.title)
  const [firstName, setFirstName] = useState(contact?.first_name)
  const [lastName, setLastName] = useState(contact?.last_name)
  const [email, setEmail] = useState(contact?.email)
  const [position, setPosition] = useState(contact?.position)
  const [phone, setPhone] = useState(contact?.phonenumber)
  const [password, setPassword] = useState('')
  const [contactCapabilities, setContactCapabilities] = useState<SelectType[]>(
    []
  )

  const [primary, setPrimary] = useState(contact ? contact.is_primary : 1)
  const [doNotSendWelecomeEmail, setDoNotSendSendWelecomeEmail] = useState(
    contact ? contact.do_not_send_welcome_email : 0
  )
  const [sendSetPassword, setSendSetPassword] = useState(
    contact ? contact.send_set_password : 0
  )

  const [EmailNotifications, setEmailNotifications] = useState<SelectType[]>(
    contact ? contact.email_notifications : []
  )
  const [Permissions, setPermissions] = useState<SelectType[]>(
    contact ? contact.permissions : []
  )

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/contacts/capabilities',
      true
    )
    API_REQUEST.send(
      {
        type: 'select'
      },
      (data: { status: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setContactCapabilities(data.data)
        }
      }
    )
  }, [])

  // eslint-disable-next-line
  const handleEmailNotificationsChange = (selected: any) => {
    setEmailNotifications(selected)
  }

  // eslint-disable-next-line
  const handlePermissionsChange = (selected: any) => {
    setPermissions(selected)
  }

  const addClientContact = () => {
    const tmpToast = toast('Saving contact', {
      position: 'top-right',
      type: 'info',
      isLoading: true,
      theme: 'light',
      className: 'text-sm'
    })

    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/client/contact/add',
      true
    )
    API_REQUEST.send(
      {
        client: client,
        title: title,
        first_name: firstName,
        last_name: lastName,
        email: email,
        position: position,
        phone: phone,
        password: password,
        primary: primary,
        do_not_send_welecome_email: doNotSendWelecomeEmail,
        send_set_password: sendSetPassword,
        email_notifications: EmailNotifications,
        permissions: Permissions
      },
      (data: { status: string; message: string; data: ClientContact[] }) => {
        if (data.status == 'success') {
          setRefreshComponent(!refreshComponent)
          setContactModelOpen(false)

          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'success',
            autoClose: 2000
          })
        } else {
          toast.update(tmpToast, {
            render: data.message,
            isLoading: false,
            type: 'error',
            autoClose: 2000
          })
        }
      }
    )
  }

  return (
    <div className="p-1">
      <div className="row">
        <Col xs={12} lg={2}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Title
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Title"
              onChange={e => setTitle(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={5}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              First Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              onChange={e => setFirstName(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={5}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Last Name
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="First Name"
              onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
        </Col>
      </div>
      <Row>
        <Col xs={12} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Position
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Position"
              onChange={e => setPosition(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Phone
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Phone"
              onChange={e => setPhone(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Email
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4}>
          <Form.Group className="mb-3">
            <Form.Label className="fs-8 text-start p-0 text-capitalize">
              Password
            </Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={e => setPassword(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={4} className="mb-3">
          <Form.Check
            type="switch"
            id="is_primary"
            label="Primary Contact"
            checked={true}
            onChange={e => e.target.checked && setPrimary(1)}
            value={primary}
          />
        </Col>
        <Col xs={12} lg={4} className="mb-3">
          <Form.Check
            type="switch"
            id="donotsendwelcomeemail"
            label="Do not send welcome email"
            checked={true}
            onChange={e => e.target.checked && setDoNotSendSendWelecomeEmail(1)}
            value={doNotSendWelecomeEmail}
          />
        </Col>
        <Col xs={12} lg={4} className="mb-3">
          <Form.Check
            type="switch"
            id="send_set_password_email"
            label="Send SET password email"
            checked={true}
            onChange={e => e.target.checked && setSendSetPassword(1)}
            value={sendSetPassword}
          />
        </Col>
      </Row>
      <Col xs={12} lg={12} className="mb-3">
        <h5 className="text-body-highlight mb-2">Permissions</h5>
        <ReactSelect
          defaultValue={contactCapabilities}
          id="permissions"
          options={contactCapabilities}
          onChange={handleEmailNotificationsChange}
          isMulti
          placeholder="Select organizer..."
        />
      </Col>
      <Col xs={12} lg={12}>
        <h5 className="text-body-highlight mb-2">Email Notifications</h5>
        <ReactSelect
          defaultValue={contactCapabilities}
          id="groups"
          options={contactCapabilities}
          onChange={handlePermissionsChange}
          isMulti
          placeholder="Select organizer..."
        />
      </Col>
      <Row className="mt-3 pt-3 border-top">
        <Col className="text-end">
          <button
            type="button"
            className="btn btn-sm btn-secondary me-2"
            onClick={() => {
              setContactModelOpen(false)
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={addClientContact}
          >
            Save
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default ClientContactModel
