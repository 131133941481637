import { Card, Col, Row, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import { useState, useEffect } from 'react'

import apiRequest from 'apiRequest'

import { SelectType, Item } from 'modules/inventory/types'
import ReactSelect from 'react-select'

const itemRightTop = ({
  className,
  productGroup,
  productTag,
  productSubGroup,
  productType,
  item,
  loading,
  setProductGroup,
  setProductTag,
  setProductSubGroup,
  setProductType
}: {
  className?: string
  productGroup: SelectType | null
  productTag: SelectType[] | null
  productSubGroup: SelectType | null
  productType: SelectType | null
  item?: Item
  loading: boolean
  setProductGroup: (value: SelectType) => void
  setProductTag: (value: SelectType[]) => void
  setProductSubGroup: (value: SelectType) => void
  setProductType: (value: SelectType) => void
}) => {
  console.log(productGroup, productType)

  const [groups, setGroups] = useState<SelectType[]>([])
  const [tags, setTags] = useState<SelectType[]>([])
  const [subGroups, setSubGroups] = useState<SelectType[]>([])
  const [productTypes, setProductTypes] = useState<SelectType[]>([])

  const [groupSelectOpen, setGroupSelectOpen] = useState<boolean>(false)
  const [tagSelectOpen, setTagSelectOpen] = useState<boolean>(false)
  const [subGroupSelectOpen, setSubGroupSelectOpen] = useState<boolean>(false)
  const [productTypeOpen, setProductTypeOpen] = useState<boolean>(false)

  console.log(productSubGroup)

  const searchGroups = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/commodity-groups/search',
      true
    )

    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setGroups(data.data)
        }
      }
    )
  }

  const searchTags = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/tags/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setTags(data.data)
        }
      }
    )
  }

  const searchSubGroups = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/sub-groups/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setSubGroups(data.data)
        }
      }
    )
  }

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/items/types/search',
      true
    )
    API_REQUEST.send(
      { type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setProductTypes(data.data)
        }
      }
    )
  }, [])

  // eslint-disable-next-line
  const handleProductTypeChange = (selected: any) => {
    setProductType(selected.value != undefined ? selected.value : '')
    setProductTypeOpen(false)
  }

  // eslint-disable-next-line
  const handleGroupChange = (selected: any) => {
    setProductGroup(selected.value != undefined ? selected.value : '')
    setGroupSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleTagChange = (selected: any) => {
    setProductTag(selected)
    setTagSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleSubGroupChange = (selected: any) => {
    setProductSubGroup(selected.value != undefined ? selected.value : [])
    setSubGroupSelectOpen(false)
  }

  return (
    <Card className={className}>
      <Card.Body>
        <h4 className="mb-4">Product Details</h4>
        {loading ? (
          <>
            <Row className="align-items-center justify-content-between g-2">
              <Col xs={12} lg={12}>
                <SkeletonTheme highlightColor="#444">
                  <Skeleton
                    count={5}
                    style={{ opacity: 0.2, marginTop: '30px' }}
                    duration={2}
                  />
                </SkeletonTheme>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="gx-3 gy-4">
            <Col xs={12} sm={6} xl={12}>
              <Form.Group>
                <Form.Label className="fs-8 text-start p-0 text-capitalize">
                  Product Type
                </Form.Label>
                <ReactSelect
                  options={productTypes}
                  placeholder="Select item type..."
                  className="small"
                  classNamePrefix="react-select"
                  onChange={handleProductTypeChange}
                  onMenuOpen={() => setProductTypeOpen(true)}
                  onBlur={() => setProductTypeOpen(false)}
                  menuIsOpen={productTypeOpen}
                  value={item?.type ? item.type : null}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={6} xl={12}>
              <div className="d-flex gap-2 mb-2">
                <h5 className="mb-0 text-body-highlight">Commodity Group</h5>
                <Link className="fw-bold fs-9" to="#!">
                  Add new group
                </Link>
              </div>
              <ReactSelect
                options={groups}
                placeholder="Select group type..."
                className="small"
                classNamePrefix="react-select"
                onInputChange={searchGroups}
                onChange={handleGroupChange}
                onMenuOpen={() => setGroupSelectOpen(true)}
                onBlur={() => setGroupSelectOpen(false)}
                menuIsOpen={groupSelectOpen}
                value={item?.group ? item.group : null}
              />
            </Col>
            <Col xs={12} sm={6} xl={12}>
              <div className="d-flex gap-2 mb-2">
                <h5 className="mb-0 text-body-highlight">Sub Group</h5>
                <Link className="fw-bold fs-9" to="#!">
                  Add new sub group
                </Link>
              </div>
              <ReactSelect
                options={subGroups}
                placeholder="Select sub group type..."
                className="small"
                classNamePrefix="react-select"
                onInputChange={searchSubGroups}
                onChange={handleSubGroupChange}
                onMenuOpen={() => setSubGroupSelectOpen(true)}
                onBlur={() => setSubGroupSelectOpen(false)}
                menuIsOpen={subGroupSelectOpen}
                value={item?.sub_group ? item.sub_group : null}
              />
            </Col>
            {!loading && (
              <Col xs={12} sm={6} xl={12}>
                <div className="d-flex gap-2 mb-2">
                  <h5 className="mb-0 text-body-highlight">Tags</h5>
                </div>
                <ReactSelect
                  options={tags}
                  placeholder="Select tags..."
                  className="small"
                  classNamePrefix="react-select"
                  onInputChange={searchTags}
                  onChange={handleTagChange}
                  onMenuOpen={() => setTagSelectOpen(true)}
                  onBlur={() => setTagSelectOpen(false)}
                  menuIsOpen={tagSelectOpen}
                  defaultValue={productTag}
                  isMulti
                />
              </Col>
            )}
          </Row>
        )}
      </Card.Body>
    </Card>
  )
}

export default itemRightTop
