import React from 'react'
import { Col, Row } from 'react-bootstrap'
import CrmStats from './CrmStats'
import Greetings from './greetings/Greetings'
import { weather } from 'data/dashboard/default'
import Weather from 'components/dashboards/default/Weather'
import TodoList from 'components/modules/project-management/todo-list/TodoList'
import Revenue from './revenue/Revenue'
import ProjectActivityCard from 'components/cards/ProjectActivityCard'
import Calendar from './calendar/Calendar'
import { Tab, Nav } from 'react-bootstrap'

import MuiChart from 'mui_area_chart'

const Crm = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col xxl={9}>
          <Greetings />
          <MuiChart />
        </Col>
        <Col xxl={3}>
          <Weather data={weather} />
        </Col>
      </Row>
      <Row className="g-3 mb-3 pt-0">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
        <Col xxl={3}>
          <TodoList />
        </Col>
      </Row>
      <Row className="g-3 mb-3 pt-0">
        <Col xxl={9}>
          <Tab.Container id="basic-tabs-example" defaultActiveKey="tasks">
            <Nav variant="underline" className="mb-3 mt-3 text-right">
              <Nav.Item>
                <Nav.Link eventKey="tasks">My Task</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="projects">My Project</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tickets">Tickets</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="annoucement">Annoucement</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tasks">
                <TodoList />
              </Tab.Pane>
              <Tab.Pane eventKey="projects">
                <TodoList />
              </Tab.Pane>
              <Tab.Pane eventKey="tickets">
                <TodoList />
              </Tab.Pane>
              <Tab.Pane eventKey="annoucement">
                <TodoList />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
        <Col xxl={3}>
          <ProjectActivityCard />
        </Col>
      </Row>
      <Row className="g-3 mb-3 pt-0">
        <Col xxl={12}>
          <Calendar />
        </Col>
      </Row>
    </>
  )
}

export default Crm
