import { Row, Form, Button, Table } from 'react-bootstrap'
// import { useState } from 'react'

// import { RowItem } from '../types'

import lng from 'lang'

const ItemTemplate = () => {
  // const [items, setItems] = useState<RowItem[]>([])

  // const addItem = () => {
  //   const newItem: RowItem = {
  //     id: '',
  //     description: '',
  //     unit_price: '',
  //     quantity: '',
  //     tax_value: '',
  //     sub_total: '',
  //     discount_amount: '',
  //     discount_percent: '',
  //     total: ''
  //   }

  //   setItems([...items, newItem])
  // }

  return (
    <Row>
      <Table className="table table-bordered table-hover mt-3" responsive>
        <thead>
          <tr>
            <th>{lng.get('purchase', ['item'])}</th>
            <th>{lng.get('purchase', ['item', 'description'])}</th>
            <th>{lng.get('purchase', ['unit_price'])}</th>
            <th>{lng.get('purchase', ['quantity'])}</th>
            <th>{lng.get('purchase', ['tax_value'])}</th>
            <th>{lng.get('purchase', ['sub_total'])}</th>
            <th>{lng.get('purchase', ['discount'])} (%)</th>
            <th>{lng.get('purchase', ['discount'])}</th>
            <th>{lng.get('purchase', ['total'])}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', ['item'])}
              />
            </td>
            <td>
              <Form.Control
                type="text"
                placeholder={lng.get('purchase', ['item', 'description'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['unit_price'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['quantity'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['tax_value'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['sub_total'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['discount']) + ' (%)'}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['discount'])}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                placeholder={lng.get('purchase', ['total'])}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={9}>
              <Button variant="default" className="btn btn-sm">
                <i className="fa fa-plus" />
                {lng.get('purchase', ['add_new_item'])}
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </Row>
  )
}

export default ItemTemplate
