import { faKey, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/base/Button'
// import AuthSocialButtons from 'components/common/AuthSocialButtons'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import apiRequest from 'apiRequest'

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const navigate = useNavigate()

  type statusColor = 'success' | 'danger' | 'primary'

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginText, setLoginText] = useState('Sign In')
  const [statusColor, setStatusColor] = useState<statusColor>('primary')

  const [disableButton, setDisableButton] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const validateEmail = (email: string) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true
    } else {
      return false
    }
  }

  const login = () => {
    setUsernameError(false)
    setPasswordError(false)

    if (username == '' || !validateEmail(username)) {
      setUsernameError(true)
      return
    }

    if (password == '') {
      setPasswordError(true)
      return
    }

    setLoginText('Authorizing')
    setDisableButton(true)

    const API = new apiRequest('https://demo.globalcloudmedia.com/authorize')
    API.send(
      {
        username: username,
        password: password
      },
      (data: {
        status: string
        token: string
        language: string
        message: string
        user: string
        last_logined: string
        role: string
      }) => {
        if (data.status == 'success') {
          setStatusColor('success')
          setLoginText('Welcome')
          localStorage.setItem('username', username)
          localStorage.setItem('token', data.token)
          localStorage.setItem('user_lng', data.language)
          localStorage.setItem('role', data.role)
          localStorage.setItem('last_logined', data.last_logined)

          localStorage.setItem(
            'toast',
            JSON.stringify({
              type: 'success',
              loading: true,
              message: 'Welcome Back ' + data.user,
              nextText: 'Last Login: ' + data.last_logined
            })
          )
          setTimeout(() => {
            navigate('/dashboard', { replace: true })
          }, 100)
        } else {
          setLoginText(data.message)
          setStatusColor('danger')
          setTimeout(() => {
            setStatusColor('primary')
            setLoginText('Sign In')
            setDisableButton(false)
          }, 2000)
        }
      }
    )
  }

  return (
    <Form
      style={{
        backgroundColor: '#fff',
        borderRadius: '10px'
      }}
      className="light__bg-light dark__text-gray-100 dark__bg-gray-1000 p-4 pt-8 pb-9"
    >
      <div className="text-center mb-4">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to your account</p>
      </div>
      <Form.Group className="mb-3 text-start was-validated">
        <Form.Label htmlFor="email">Email address</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="name@example.com"
            onChange={e => setUsername(e.target.value)}
            required={true}
            style={{ borderColor: usernameError ? 'red' : '' }}
          />
          <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
            onChange={e => setPassword(e.target.value)}
            required={true}
            style={{ borderColor: passwordError ? 'red' : '' }}
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      {/* <div className="position-relative">
        <hr className="bg-body-secondary mt-5 mb-4" />
        <div className="divider-content-center">login with social</div>
      </div> */}
      {/* <AuthSocialButtons title="Sign in" /> */}
      <Row className="flex-between-center mb-4">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semibold"
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Button
        type="submit"
        variant={statusColor}
        onClick={login}
        className="w-100 mb-3"
        disabled={disableButton}
      >
        {loginText}
        {loginText === 'Authorizing' && (
          <Spinner
            animation="grow"
            size="sm"
            style={{ width: 12, height: 12 }}
            className="ms-2"
            as="span"
            role="status"
            aria-hidden="true"
          />
        )}
      </Button>
      {/* <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>
      </div> */}
    </Form>
  )
}

export default SignInForm
