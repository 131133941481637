import { useEffect, useState } from 'react'
import { Col, Form, Nav, Row, Tab } from 'react-bootstrap'
import FeatherIcon from 'feather-icons-react'
import ReactSelect from 'components/base/ReactSelect'
import classNames from 'classnames'
import VariantFormCard from './VariantFormCard'

import { Variant, NavItemType, SelectType, Item } from 'modules/inventory/types'
import AccountingTab from './AccountingTab'

import apiRequest from 'apiRequest'

const NavItem = ({ item, isLast }: { item: NavItemType; isLast?: boolean }) => {
  return (
    <Nav.Link
      eventKey={item.eventKey}
      className={classNames(
        'text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center',
        {
          'border-bottom-sm border-end border-end-sm-0': !isLast
        }
      )}
    >
      <FeatherIcon icon={item.icon} size={16} className="me-sm-2 nav-icons" />
      <span className="d-none d-sm-inline">{item.label}</span>
    </Nav.Link>
  )
}

const navItems: NavItemType[] = [
  {
    label: 'Pricing',
    eventKey: 'pricing',
    icon: 'tag'
  },
  {
    label: 'Warehouse',
    eventKey: 'warehouse',
    icon: 'shopping-bag'
  },
  {
    label: 'Alert Quantity',
    icon: 'bell',
    eventKey: 'global-delivery'
  },
  {
    label: 'Open Stock',
    icon: 'package',
    eventKey: 'restock'
  },
  {
    label: 'TAX',
    icon: 'dollar-sign',
    eventKey: 'tax'
  },
  {
    label: 'Accounting',
    icon: 'credit-card',
    eventKey: 'accounting'
  },
  {
    label: 'Custom Fileds',
    icon: 'list',
    eventKey: 'custom_fields'
  },
  {
    label: 'Variants',
    icon: 'list',
    eventKey: 'variants'
  }
]

const InventoryTab = ({
  variants,
  productTaxes,
  productWarehouses,
  productDefaultPrice,
  productDefaultProfitRate,
  productSellingPrice,
  item,
  setVariants,
  setProductTaxes,
  setProductWarehouses,
  setProductDefaultProfitRate,
  setProductDefaultPrice,
  setProductSellingPrice
}: {
  variants: Variant[]
  productTaxes: SelectType[]
  productWarehouses: SelectType[]
  item?: Item
  productDefaultPrice: number | null
  productSellingPrice: number | null
  productDefaultProfitRate: number | null
  setVariants: (value: Variant[]) => void
  setProductTaxes: (value: SelectType[]) => void
  setProductWarehouses: (value: SelectType[]) => void
  setProductDefaultProfitRate: (value: number) => void
  setProductDefaultPrice: (value: number) => void
  setProductSellingPrice: (value: number) => void
}) => {
  const [taxes, setTaxes] = useState<SelectType[]>(productTaxes)
  const [warehouses, setWarehouses] = useState<SelectType[]>(productWarehouses)

  const [taxesSelectOpen, setTaxesSelectOpen] = useState<boolean>(false)
  const [warehousesSelectOpen, setWarehousesSelectOpen] =
    useState<boolean>(false)

  useEffect(() => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/warehouses/search',
      true
    )
    API_REQUEST.send(
      {
        type: 'select'
      },
      (data: { status: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setWarehouses(data.data)
        }
      }
    )
  }, [])

  const searchTaxes = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/taxes/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setTaxes(data.data)
        }
      }
    )
  }

  const searchWarehouses = (keyword: string) => {
    const API_REQUEST = new apiRequest(
      'https://demo.globalcloudmedia.com/inventory/warehouses/search',
      true
    )
    API_REQUEST.send(
      { keyword: keyword, type: 'select' },
      (data: { status: string; message: string; data: SelectType[] }) => {
        if (data.status == 'success') {
          setWarehouses(data.data)
        }
      }
    )
  }

  // eslint-disable-next-line
  const handleWarehousesChange = (selected: any) => {
    setProductWarehouses(selected.value != undefined ? selected.value : [])
    setWarehousesSelectOpen(false)
  }

  // eslint-disable-next-line
  const handleTaxChange = (selected: any) => {
    setProductTaxes(selected.value != undefined ? selected.value : [])
    setTaxesSelectOpen(false)
  }

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="pricing">
      <Row className="g-0 border-top border-bottom">
        <Col xs={12} sm={4}>
          <Nav className="flex-sm-column border-bottom border-bottom-sm-0 border-end-sm fs-9 vertical-tab h-100 justify-content-between">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                item={item}
                isLast={index === navItems.length - 1}
              />
            ))}
          </Nav>
        </Col>

        <Col xs={12} sm={8}>
          <Tab.Content className="py-3 ps-sm-4 h-100">
            <Tab.Pane eventKey="pricing">
              <h4 className="mb-3 d-sm-none">Pricing</h4>
              <Row className="g-3">
                <Col xs={12} lg={12}>
                  <h5 className="mb-2 text-body-highlight">
                    Default Profit Rate (%)
                  </h5>
                  <Form.Control
                    type="number"
                    placeholder="$$$"
                    onChange={e => {
                      // eslint-disable-next-line
                      var profit_rate = parseFloat(e.target.value) || 0
                      // eslint-disable-next-line
                      var purchase_price =
                        parseFloat(
                          productDefaultPrice != null
                            ? productDefaultPrice.toString()
                            : '0'
                        ) || 0
                      setProductDefaultProfitRate(profit_rate)
                      setProductSellingPrice(
                        purchase_price + (profit_rate / 100) * purchase_price
                      )
                    }}
                    value={
                      productDefaultProfitRate == null
                        ? ''
                        : productDefaultProfitRate
                    }
                    step="0.01"
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <h5 className="mb-2 text-body-highlight">Purchase price</h5>
                  <Form.Control
                    type="number"
                    placeholder="$$$"
                    defaultValue={item?.default_price}
                    onChange={e => {
                      // eslint-disable-next-line
                      var purchase_price = parseFloat(e.target.value) || 0
                      // eslint-disable-next-line
                      var profit_rate =
                        parseFloat(
                          productDefaultProfitRate != null
                            ? productDefaultProfitRate.toString()
                            : '0'
                        ) || 0
                      setProductDefaultPrice(purchase_price)
                      setProductSellingPrice(
                        purchase_price + (profit_rate / 100) * purchase_price
                      )
                    }}
                    value={
                      productDefaultPrice == null ? '' : productDefaultPrice
                    }
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <h5 className="mb-2 text-body-highlight">Selling price</h5>
                  <Form.Control
                    type="text"
                    placeholder="$$$"
                    onChange={e =>
                      setProductSellingPrice(parseFloat(e.target.value) || 0)
                    }
                    value={
                      productSellingPrice == null ? '' : productSellingPrice
                    }
                  />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="restock" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Open Stock</h5>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="tax" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Taxes</h5>
                <ReactSelect
                  options={taxes}
                  placeholder="Select taxes..."
                  className="small"
                  classNamePrefix="react-select"
                  onInputChange={searchTaxes}
                  onChange={handleTaxChange}
                  onMenuOpen={() => setTaxesSelectOpen(true)}
                  onBlur={() => setTaxesSelectOpen(false)}
                  menuIsOpen={taxesSelectOpen}
                  isMulti
                />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="global-delivery" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Alert Quantity</h5>
                <Row className="g-3">
                  <Col xs={12} lg={6}>
                    <h5 className="mb-2 text-body-highlight">Minimim QTY</h5>
                    <Form.Control type="text" placeholder="$$$" />
                  </Col>
                  <Col xs={12} lg={6}>
                    <h5 className="mb-2 text-body-highlight">Maximum QTY</h5>
                    <Form.Control type="text" placeholder="$$$" />
                  </Col>
                </Row>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="accounting" className="h-100">
              <h5 className="mb-3 text-body-highlight">Accounting</h5>

              <Col xs={12} lg={12}>
                <AccountingTab />
              </Col>
            </Tab.Pane>

            <Tab.Pane eventKey="custom_fields" className="h-100">
              <h5 className="mb-3 text-body-highlight">Custom Fileds</h5>
              <div className="row"></div>
            </Tab.Pane>

            <Tab.Pane eventKey="variants" className="h-100">
              <VariantFormCard variants={variants} setVariants={setVariants} />
            </Tab.Pane>
            <Tab.Pane eventKey="warehouse" className="h-100">
              <h5 className="mb-3 text-body-highlight">Warehouse</h5>

              <Col xs={12} lg={12}>
                <ReactSelect
                  options={warehouses}
                  placeholder="Select warehouse..."
                  className="small"
                  classNamePrefix="react-select"
                  onInputChange={searchWarehouses}
                  onChange={handleWarehousesChange}
                  onMenuOpen={() => setWarehousesSelectOpen(true)}
                  onBlur={() => setWarehousesSelectOpen(false)}
                  menuIsOpen={warehousesSelectOpen}
                  isMulti
                />
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default InventoryTab
